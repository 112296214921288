import dynamic from "next/dynamic";
const HtmlParserSP = dynamic(
	() => import("@/app/components/molecules/HtmlParser/HtmlParserSP")
);
const HtmlParserLB = dynamic(
	() => import("@/app/components/molecules/HtmlParser/HtmlParserLB")
);

type Props = {
	bodyHtml?: string;
	cardItem?: any;
	type?: string;
	position?: any;
	total?: any;
	hasButton?: boolean;
	isLiveBlog?: boolean;
	isMain?: boolean;
	isSummary?: boolean;
	isVisibleObserver?: boolean;
	dataLayer?: any;
	isPinned?: boolean;
	typeMedia?: string;
	adOps?: {
		hasTeads: boolean;
		hasSeedTag: boolean;
	};
};

export default function StoryPageBody({
	bodyHtml = "",
	cardItem,
	type = "",
	position = 1,
	total = 1,
	hasButton,
	isLiveBlog = false,
	isMain,
	isSummary,
	isVisibleObserver,
	dataLayer,
	isPinned,
	typeMedia = "",
	adOps,
}: Props) {
	return (
		<>
			{!isLiveBlog && !isMain && !isSummary && (
				<HtmlParserSP
					bodyHtml={bodyHtml}
					cardItem={cardItem}
					type={type}
					position={position}
					total={total}
					hasButton
					typeMedia={typeMedia}
					adOps={adOps}
				/>
			)}
			{isLiveBlog && (
				<HtmlParserLB
					bodyHtml={bodyHtml}
					type={type}
					isLiveBlog={isLiveBlog}
					hasButton={hasButton}
					isSummary={isSummary}
					isMain={isMain}
					isVisibleObserver={isVisibleObserver}
					isPinned={isPinned}
					dataLayer={dataLayer}
					typeMedia={typeMedia}
				/>
			)}
		</>
	);
}
